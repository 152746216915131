.events-log {
    overflow-y: scroll;
    height: 200px;
    display: flex;
    flex-direction: column-reverse;
    width: calc(100% - 5px);
    margin-left: 5px;
}

.events-log .event-log-entry {
    border-left: 1px solid black;
    padding-left: 10px;
    margin-bottom: 4px;
}