#navbar {
  width: 100%;
  z-index: 10;
  background: #000;
  border-bottom: 10px solid #FFE01B;
  box-shadow: 0 5px 5px rgb(0 0 0 / 13%);
}

.navbar-menu {
  background: #000;
}

#navbar.is-active {
  border-bottom: none;
}

.navbar-menu.is-active {
  border-bottom: 10px solid #FFE01B;
  box-shadow: 0 5px 5px rgb(0 0 0 / 13%);
}

#navbar .button {
  background: #FFE01B;
  color: black;
  border: none;
}

#brand-logo {
  position: absolute;
  width: 140px;
  justify-content: center;
}

#navbar .shield .logo-shield {
  width: 75px;
  display: block;
}

.shield img {
  height: 98px;
  width: 75px;
  max-height: 200px;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

#navbar .gamecode.button {
  background-color: black;
  color: #FFE01B;
  cursor: auto;
  user-select: auto;
}

.handout {
  margin-right: 10px;
}

.handout-disabled, .roll-button-disabled {
  color: lightgray;
}

.player-list {
  display: flex;
  flex-wrap: wrap;
}

.player.card {
  flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.player.player-current {
  border: 2px solid green;
}

.player.player-paused .card-header-title {
  color: lightgray;
}

.dices {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dices button {
  font-size: xx-large;
}